import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Pipe({
    name: 'requiredAsterisk',
})
export class RequiredAsteriskPipe implements PipeTransform {
    transform(value: string, control: boolean | AbstractControl): string {
        if (control instanceof AbstractControl && this.isRequiredField(control)) {
            return `${value} *`;
        } else if (!(control instanceof AbstractControl) && coerceBooleanProperty(control)) {
            return `${value} *`;
        }
        return value;
    }

    private isRequiredField(control: AbstractControl) {
        if (!control.validator) {
            return false;
        }
        const validator = control.validator({} as AbstractControl);
        return validator && validator.required;
    }
}
