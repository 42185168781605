import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { AuthService } from './auth.service';

export function storageFactory(): OAuthStorage {
    return localStorage;
}

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        OAuthModule.forRoot({
            resourceServer: {
                sendAccessToken: true,
            },
        }),
    ],
    providers: [AuthService, { provide: OAuthStorage, useFactory: storageFactory }],
})
export class AuthModule {
    static forRoot(config: Provider): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [config],
        };
    }
}
