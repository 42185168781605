<!-- Navigation -->
<ng-container *ngIf="isScreenSmall && navigation?.default?.length">
    <fuse-vertical-navigation
        class="dark bg-gray-900 print:hidden"
        [mode]="'over'"
        [name]="'mainNavigation'"
        [navigation]="navigation?.default"
        [opened]="false">
        <!-- Navigation header hook -->
        <ng-container fuseVerticalNavigationContentHeader>
            <!-- Logo -->
            <div class="flex items-center h-20 pt-6 px-8">
                <ng-container *ngIf="logos === 'ridango'">
                    <img class="dark:hidden inline-block h-16" src="assets/rui/logos/2022-logo.svg" alt="Logo image" />
                    <img class="hidden dark:inline-block h-16" src="assets/rui/logos/2022-logo-white.svg" alt="Logo image" />
                </ng-container>
                <ng-container *ngIf="logos === 'sfey'">
                    <img class="dark:hidden inline-block h-8" src="assets/rui/logos/SFEY_Logo_RGB_GB.svg" alt="Logo image" />
                    <img class="hidden dark:inline-block h-8" src="assets/rui/logos/SFEY_Logo_RGB_GW.svg" alt="Logo image" />
                </ng-container>
            </div>
        </ng-container>
    </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <ng-container *ngIf="!isScreenSmall">
            <!-- Logo -->
            <div class="flex items-center mx-2 lg:mr-8">
                <div class="hidden lg:flex">
                    <img
                        class="dark:hidden w-48"
                        src="assets/rui/logos/2022-logo.svg">
                    <img
                        class="hidden dark:flex w-48"
                        src="assets/rui/logos/2022-logo-white.svg">
                </div>
                <img
                    class="flex lg:hidden w-48"
                    src="assets/rui/logos/2022-logo.svg">
            </div>
            <!-- Horizontal navigation -->
            <fuse-horizontal-navigation
                class="mr-2"
                [name]="'mainNavigation'"
                [navigation]="navigation?.default"></fuse-horizontal-navigation>
        </ng-container>
        <!-- Navigation toggle button -->
        <ng-container *ngIf="isScreenSmall && navigation?.default?.length">
            <button
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
        </ng-container>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-1 sm:space-x-2">
            <languages></languages>
            <tenant></tenant>
            <user>
                <ng-container *ngFor="let item of toolbarDropdownItems$ | async">
                    <mat-divider *ngIf="item.type === 'divider'"></mat-divider>
                    <button *ngIf="item.type === 'button'" mat-menu-item (click)="item.onClick()">
                        <mat-icon *ngIf="item.icon" class="icon" [svgIcon]="item.icon"></mat-icon>
                        {{ item.title }}
                    </button>
                    <div class="p-4" *ngIf="item.type === 'text'">
                        <span class="flex flex-col leading-none">
                            <span>{{ item.title }}</span>
                            <span class="mt-1.5 text-md font-medium">{{ item.value }}</span>
                        </span>
                    </div>
                </ng-container>
            </user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto w-full">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>


</div>
