import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HasRolesDirective } from './has-roles.directive';
import { IsAuthorizedPipe } from './is-authorized.pipe';

@NgModule({
    declarations: [HasRolesDirective, IsAuthorizedPipe],
    exports: [HasRolesDirective, IsAuthorizedPipe],
    imports: [CommonModule],
})
export class AuthorizationModule {}
