import { IStorageService } from './storage.interface';

export class StorageService implements IStorageService {
    private storage: Storage;

    constructor(storage: Storage = localStorage) {
        this.storage = storage;
    }

    get length(): number {
        return Object.keys(this.storage).length;
    }

    key(index: number): string | null {
        return Object.keys(this.storage)[index] ?? null;
    }

    setItem(key: string, item: unknown): void {
        this.storage.setItem(key, JSON.stringify(item));
    }

    getItem<TItemType>(key: string): TItemType | null {
        const item = this.storage.getItem(key);
        if (!item) {
            return null;
        }
        return this.parse(item) as TItemType;
    }

    removeItem(key: string): void {
        this.storage.removeItem(key);
    }

    clear(): void {
        this.storage.clear();
    }

    private parse(input: unknown): unknown {
        try {
            return JSON.parse(input as string);
        } catch (e) {
            return input;
        }
    }
}
