import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { StorageFactory } from '@payments/list-manager';
import { AuthService } from '@ridango/auth';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import { ModalService } from '../../widgets/modal/modal.service';
import { SessionExpirationComponent } from './session-expiration/session-expiration.component';

@Injectable({
    providedIn: 'root',
})
export class UserActivityService {
    private readonly SESSION_DURATION_IN_SECONDS = 15 * 60;
    private readonly PROMPT_USER_FOR_EXTENSION = 60;
    private userActivityEvent$ = new Subject();
    private lastActivity: number;

    sessionTimeLeft$ = new BehaviorSubject<number>(this.SESSION_DURATION_IN_SECONDS);

    private interval;
    private sessionExpirationModal: MatDialogRef<SessionExpirationComponent>;

    constructor(private authenticationService: AuthService, private modalService: ModalService) {
        this.sessionTimeLeft$
            .pipe(filter((timeleft) => timeleft <= this.PROMPT_USER_FOR_EXTENSION))
            .subscribe(() => this.promptUserForSessionExtension());

        this.sessionTimeLeft$.pipe(filter((timeleft) => timeleft <= 0)).subscribe(() => this.endSession());

        this.userActivityEvent$
            .pipe(
                debounceTime(2000),
                switchMap(() => this.currentSessionRequest())
            )
            .subscribe(() => this.start());
    }

    start() {
        if (this.authenticationService.isAuthenticated) {
            this.extend();
        }
    }

    stop() {
        this.clearIntervalIfExists();
    }

    handleUserRequest() {
        this.userActivityEvent$.next(undefined);
    }

    extendSession() {
        this.modalService.close(this.sessionExpirationModal?.id);
        this.sessionExpirationModal = null;
        this.stop();
        this.currentSessionRequest().subscribe(() => this.start());
    }

    endSession() {
        this.authenticationService.logout();
        this.clearStorage();
        this.modalService.closeAll();
        this.sessionExpirationModal = null;
        this.stop();
    }

    private currentSessionRequest() {
        return this.authenticationService.currentSessionRequest();
    }

    private clearStorage() {
        StorageFactory.sessionStorage.clear();
        StorageFactory.urlStorage.clear();
    }

    private promptUserForSessionExtension() {
        if (this.sessionExpirationModal) return;
        this.sessionExpirationModal = this.modalService.open(SessionExpirationComponent, undefined, {closeWithEscWithoutConfirmationStep: true});
    }

    private extend() {
        this.clearIntervalIfExists();
        this.lastActivity = Date.now();

        this.interval = setInterval(() => {
            const elapsedTime = (Date.now() - this.lastActivity) / 1000;
            const timeLeft = this.SESSION_DURATION_IN_SECONDS - elapsedTime;
            this.sessionTimeLeft$.next(Math.round(timeLeft));
        }, 1000);
    }

    private clearIntervalIfExists() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }
}
