import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from '@ridango/ui/notifications';

@Injectable({
    providedIn: 'root',
})
export class SnackbarService {
    constructor(private service: NotificationsService, private translate: TranslateService) {}

    addSuccess(info: string, options = { timeout: 5000 }) {
        const message = this.translate.instant(info);
        const config = { duration: options.timeout };
        return this.service.addSuccess(message, config);
    }

    addError(info: string) {
        const message = this.translate.instant(info || 'notifications.unexpectedError');
        return this.service.addError(message);
    }

    addInfo(info: string) {
        const message = this.translate.instant(info);
        return this.service.addInfo(message);
    }

    addErrorAction(info: string, label: string, callbackFn: () => void) {
        const message = this.translate.instant(info);
        const actionLabel = this.translate.instant(label);

        return this.service.addError({
            title: message,
            action: {
                label: actionLabel,
                callbackFn: callbackFn,
            },
        });
    }
}
