import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Optional, ViewEncapsulation } from '@angular/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { I18nService, LANGUAGE } from '../../../../../../i18n/src/lib/i18n.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'languages',
    templateUrl: './languages.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'languages',
})
export class LanguagesComponent {
    /**
     * Constructor
     */
    constructor(private _changeDetectorRef: ChangeDetectorRef, @Optional() public i18nService?: I18nService) {}

    get availableLangs() {
        return this.i18nService?.availableLanguages ?? [];
    }

    getActiveLabel(): string {
        return this.availableLangs.find((lang) => lang.id === this.i18nService.activeLang).label;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the active lang
     *
     * @param lang
     */
    setActiveLang(lang: LANGUAGE): void {
        this.i18nService.activeLang = lang;
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item) {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------
}
