import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { filter, take } from 'rxjs/operators';
import { ConfirmationComponent, IConfirmationComponentData } from './confirmation/confirmation.component';

export interface ExtendedDialogConfigs extends MatDialogConfig {
    closeWithEscWithoutConfirmationStep?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    private readonly defaultOptions: MatDialogConfig = {
        width: '500px',
        disableClose: true,
    };

    private openedDialogIds: string[] = [];

    constructor(public dialog: MatDialog) {}

    openLarge<T>(component: ComponentType<T>, data?: unknown, options?: ExtendedDialogConfigs) {
        return this.open<T>(component, data, { ...options, width: '800px' });
    }

    open<T>(component: ComponentType<T>, data?: unknown, options?: ExtendedDialogConfigs): MatDialogRef<T> {
        const modalOptions = {
            ...this.defaultOptions,
            ...options,
            data,
        };

        const dialog = this.dialog.open(component, modalOptions);

        this.openedDialogIds.push(dialog?.id);

        if (options && options.closeWithEscWithoutConfirmationStep) {
            // Improve a11y by allowing dialog to be closed with Esc key
            dialog
                .keydownEvents()
                .pipe(
                    filter((event) => event.key === 'Escape'),
                    take(1)
                )
                .subscribe(() => this.close(dialog?.id));
        }

        return dialog;
    }

    openConfirm(options: IConfirmationComponentData): MatDialogRef<ConfirmationComponent> {
        return this.open(
            ConfirmationComponent,
            {
                ...options,
                closeModalAction: () => this.close(),
            },
            { width: '400px', closeWithEscWithoutConfirmationStep: true }
        );
    }

    openDelete(options: IConfirmationComponentData): MatDialogRef<ConfirmationComponent> {
        return this.openConfirm({
            title: 'common.confirmDeleteTitle',
            yesButtonText: 'common.delete',
            yesButtonColor: 'warn',
            ...options,
        });
    }

    close(id?: string, response?: unknown): void {
        if (!this.openedDialogIds?.length) {
            return null;
        }
        this.closeModalById(id ?? this.lastOpenedModalId, response);
    }

    closeAll(): void {
        this.dialog.closeAll();
    }

    private closeModalById(id: string, response?: unknown): void {
        this.dialog.getDialogById(id)?.close(response);
    }

    private get lastOpenedModalId() {
        if (!this.openedDialogIds?.length) {
            return null;
        }
        return this.openedDialogIds.pop();
    }
}
