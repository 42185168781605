import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackBarData } from '../notifications.service';

@Component({
    templateUrl: './notification.component.html',
})
export class NotificationComponent {
    data: SnackBarData;

    constructor(
        public snackBarRef: MatSnackBarRef<NotificationComponent>,
        @Inject(MAT_SNACK_BAR_DATA) data: SnackBarData
    ) {
        this.data = data;
    }

    dismiss(): void {
        this.snackBarRef.dismissWithAction();
    }
}
