<payments-modal [title]="title | translate" [loading]="!formGenerator">
    <ng-container content>
        <fuse-alert [type]="alertLevel" class="mb-6" *ngIf="alertMessage">
            {{ alertMessage | translate }}
        </fuse-alert>
        <payments-form-generator [formGenerator]="formGenerator"> </payments-form-generator>
    </ng-container>

    <ng-container actions>
        <payments-form-discard-with-confirmation [form]="formGenerator?.form" (confirm)="cancel()" [disabled]="loading">
        </payments-form-discard-with-confirmation>

        <button mat-raised-button (click)="submit(formGenerator?.form)" color="primary" [loading]="loading">
            {{ submitButtonText | translate }}
        </button>
    </ng-container>
</payments-modal>
