import { Component, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { UserActivityService } from '../user-activity.service';

@Component({
    selector: 'payments-session-expiration',
    templateUrl: './session-expiration.component.html',
    styleUrls: ['./session-expiration.component.scss'],
})
export class SessionExpirationComponent implements OnDestroy {
    timeLeft$ = this.userActivityService.sessionTimeLeft$.pipe(tap((timeLeft) => this.flashTitle(timeLeft)));

    constructor(
        public userActivityService: UserActivityService,
        private title: Title,
        private translate: TranslateService
    ) {}

    ngOnDestroy(): void {
        this.title.setTitle(this.translate.instant('common.appName'));
    }

    extendSession() {
        this.userActivityService.extendSession();
    }

    endSession() {
        this.userActivityService.endSession();
    }

    private flashTitle(period: number) {
        if (period % 2 === 0) {
            return this.title.setTitle(this.translate.instant('common.continueSessionQuestion'));
        }
        return this.title.setTitle(this.translate.instant('common.appName'));
    }
}
