import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RolesResponse } from './roles.models';

@Injectable({
    providedIn: 'root',
})
export class RolesService {
    constructor(private http: HttpClient) {}

    getRoles() {
        return this.http.get<RolesResponse>(`/admin/roles`);
    }

    selectRole(roleId: number) {
        return this.http.put<RolesResponse>(`/admin/roles/${roleId}/active`, null);
    }
}
