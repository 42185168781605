import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { NotificationComponent } from './notification/notification.component';

export interface NotificationItem {
    id?: string;
    code?: string;
    title?: string;
    detail?: string;
    value?: string;
    action?: {
        label: string;
        callbackFn: () => void;
    };
}

type NotificationInput = string | NotificationItem[] | NotificationItem;
type NotificationType = 'error' | 'warning' | 'info' | 'success';
export type SnackBarData = { type: NotificationType; items: NotificationItem[] };

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    constructor(private snackBar: MatSnackBar) {}

    baseConfig: MatSnackBarConfig = {
        verticalPosition: 'bottom',
        horizontalPosition: 'center',
    };

    classList = {
        info: ['bg-primary-50', 'text-primary', 'border', 'border-primary'],
        success: ['bg-success-50', 'text-success', 'border', 'border-success'],
        warning: ['bg-yellow-50', 'text-yellow-600', 'border', 'border-yellow-600'],
        error: ['bg-warn-50', 'text-warn', 'border', 'border-warn'],
    };

    addInfo(info: NotificationInput, config: MatSnackBarConfig = {}) {
        const data: SnackBarData = {
            type: 'info',
            items: null,
        };

        data.items = this.unifyItems(info);

        return this.openSnackbar(data, this.classList.info, {
            duration: 5000,
            ...config,
        });
    }

    addSuccess(success: NotificationInput, config: MatSnackBarConfig = {}) {
        const data: SnackBarData = {
            type: 'success',
            items: null,
        };

        data.items = this.unifyItems(success);

        return this.openSnackbar(data, this.classList.success, {
            duration: 5000,
            ...config,
        });
    }

    addWarning(warning: NotificationInput, config: MatSnackBarConfig = {}) {
        const data: SnackBarData = {
            type: 'warning',
            items: null,
        };

        data.items = this.unifyItems(warning);

        return this.openSnackbar(data, this.classList.warning, config);
    }

    addError(error: NotificationInput, config: MatSnackBarConfig = {}) {
        const data: SnackBarData = {
            type: 'error',
            items: null,
        };

        data.items = this.unifyItems(error);

        return this.openSnackbar(data, this.classList.error, config);
    }

    private unifyItems(input: NotificationInput): NotificationItem[] {
        if (typeof input === 'string') {
            return [{ title: input }];
        }
        if (Array.isArray(input)) {
            return input;
        }
        return [input];
    }

    private openSnackbar(data: SnackBarData, classes: string[], config: MatSnackBarConfig = {}) {
        return this.snackBar.openFromComponent(NotificationComponent, {
            ...this.baseConfig,
            ...config,
            ...{
                data: data,
                panelClass: [...classes, ...(config.panelClass ?? [])],
            },
        });
    }
}
