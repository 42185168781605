<div class="snackbar-container {{ notification?.type }}" [style]="animationStyle">
    <div class="content">
        <p [innerHTML]="notification?.info | translate"></p>
    </div>
    <div class="close">
        <button mat-button aria-label="Close" (click)="close()">
            {{ 'common.close' | translate }}
        </button>
    </div>
</div>
