import { AUTH_CONFIG, AuthConfig } from '@ridango/auth';
import { EAppRoutes } from '../navigation.models';

export default {
    provide: AUTH_CONFIG,
    useFactory: (env): AuthConfig => ({
        clientId: env.OAUTH_CLIENT_ID,
        loginUrl: `${env.TPS_AUTH_URL}/auth`,
        logoutUrl: location.origin,
        oidc: false,
        scope: '',
        disablePKCE: true,
        responseType: 'code',
        requestAccessToken: true,
        redirectUri: window.location.origin + '/' + EAppRoutes.AfterAuth,
        tokenEndpoint: `${env.TPS_AUTH_URL}/oauth/token`,
        userinfoEndpoint: `${env.TPS_AUTH_URL}/oauth/userinfo`,
        sessionUrl: `${env.TPS_AUTH_URL}/rest/auth/session/current`,
        openUri: (uri) => {
            // auth server expects 'redirect_url' instead of 'redirect_uri'
            if (uri.includes('response_type=code')) {
                uri = uri.replace('redirect_uri', 'redirect_url');
            }
            location.href = uri;
        },
    }),
    deps: ['ENV'],
};
