<payments-loading-section *ngIf="loading"></payments-loading-section>

<ng-container *ngIf="!loading">
    <div mat-dialog-content class="dialog-content">
        <h1 *ngIf="title">{{ title }}</h1>
        <ng-content select="[content]"></ng-content>
    </div>
    <div mat-dialog-actions class="dialog-actions">
        <ng-content select="[actions]"></ng-content>
    </div>
</ng-container>
