import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
export interface DescriptionListItem {
    label: string;
    value: string | number | boolean | null;
}
@Component({
    selector: 'payments-description-list-item',
    templateUrl: './description-list-item.component.html',
    styleUrls: ['./description-list-item.component.scss'],
})
export class DescriptionListItemComponent implements AfterViewInit {
    @Input() label: string;
    @Input() value: string | number | boolean | null = 'undefined';
    @Input() noContentDisplay = '-';

    @ViewChild('contentProjection') contentProjection: ElementRef;

    hasContentProjection$ = new BehaviorSubject<boolean>(false);

    constructor(private cdRef: ChangeDetectorRef) {}

    ngAfterViewInit() {
        // https://github.com/angular/angular/issues/26083#issuecomment-459510142
        const detailContentProjection: NodeList = (this.contentProjection.nativeElement as HTMLElement).childNodes;
        for (let x = 0; x < detailContentProjection.length; x++) {
            if (detailContentProjection.item(x).nodeType !== 8) {
                this.hasContentProjection$.next(true); // nodeType == 8 means is a comment
            }
        }

        this.cdRef.detectChanges();
    }
}
