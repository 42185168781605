import { IStorageService } from './storage.interface';

export class QueryStringService implements IStorageService {
    get length(): number {
        if (!window.location.search) {
            return 0;
        }
        return this.getAll().length ?? 0;
    }

    key(index: number): string | null {
        if (!this.length) {
            return null;
        }
        const keys = this.getAll()?.map((item) => item.split('=')[0]);

        return keys[index] ?? null;
    }

    setItem(key: string, item: unknown): void {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set(key, this.stringify(item));
        this.push(searchParams);
    }

    getItem<TItemType>(key: string): TItemType | null {
        const searchParams = new URLSearchParams(window.location.search);
        const item = searchParams.get(key);
        if (!item) {
            return null;
        }
        return this.parse(item) as TItemType;
    }

    removeItem(key: string): void {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete(key);
        this.push(searchParams);
    }

    clear(): void {
        this.push(new URLSearchParams());
    }

    private push(params: URLSearchParams) {
        const path = this.composePath(params);
        return window.history.replaceState({ path }, '', path);
    }

    private composePath(params: URLSearchParams) {
        const { protocol, host, pathname } = window.location;
        if (params.toString()) {
            return `${protocol}//${host}${pathname}?${params.toString()}`;
        }
        return `${protocol}//${host}${pathname}`;
    }

    private getAll(): string[] {
        return window.location.search?.replace('?', '')?.split('&');
    }

    private stringify(input: unknown): string {
        if (typeof input === 'string') {
            return input;
        }
        return JSON.stringify(input);
    }
    private parse(input: unknown): unknown {
        try {
            return JSON.parse(input as string);
        } catch (e) {
            return input;
        }
    }
}
