import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'payments-table-loader',
    templateUrl: './table-loader.component.html',
    styleUrls: ['./table-loader.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TableLoaderComponent {
    @Input() loading: boolean;
}
