import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { AuthModule } from '@ridango/auth';
import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';

@NgModule({
    declarations: [LoginComponent],
    imports: [MatButtonModule, LoginRoutingModule, AuthModule],
})
export class LoginModule {}
