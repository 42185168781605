<payments-modal [title]="'common.sessionIsExpiringInSeconds'| translate : { timeLeft: (timeLeft$ | async) }">
    <ng-container content>
        {{ 'common.sessionExpirationQuestion' | translate }}
    </ng-container>
    <ng-container actions>
        <button mat-button (click)="endSession()">
            {{ 'common.no' | translate }}
        </button>
        <button mat-raised-button (click)="extendSession()" color="primary">
            {{ 'common.yes' | translate }}
        </button>
    </ng-container>
</payments-modal>
