<!-- Navigation -->
<fuse-vertical-navigation
    class="bg-white dark:bg-transparent print:hidden"
    [appearance]="navigationAppearance"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation?.default"
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div *ngIf="navigationAppearance === 'dense'" class="flex items-center justify-center h-16">
            <img *ngIf="logos === 'ridango'" class="w-8" src="assets/rui/logos/2022-logo-small.svg" alt="Logo image" />
            <img *ngIf="logos === 'sfey'" class="w-8" src="assets/rui/logos/SFEY_Circle_Symbol_BW" alt="Logo image" />
        </div>
        <div *ngIf="navigationAppearance === 'default'" class="flex items-center justify-center h-16 shadow mb-4">
            <ng-container *ngIf="logos === 'ridango'">
                <img class="dark:hidden inline-block h-4" src="assets/rui/logos/2022-logo.svg" alt="Logo image" />
                <img class="hidden dark:inline-block h-4" src="assets/rui/logos/2022-logo-white.svg" alt="Logo image" />
            </ng-container>
            <ng-container *ngIf="logos === 'sfey'">
                <img class="dark:hidden inline-block h-8" src="assets/rui/logos/SFEY_Logo_RGB_GB.svg" alt="Logo image" />
                <img class="hidden dark:inline-block h-8" src="assets/rui/logos/SFEY_Logo_RGB_GW.svg" alt="Logo image" />
            </ng-container>
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <div class="flex items-center pr-2 space-x-2">
            <!-- Navigation toggle button -->
            <button mat-icon-button class="inline-flex lg:hidden" (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
            <!-- Navigation appearance toggle button -->
            <!-- <button class="hidden md:inline-flex" mat-icon-button (click)="toggleNavigationAppearance()">
                <mat-icon [svgIcon]="'heroicons_outline:switch-horizontal'"></mat-icon>
            </button> -->
        </div>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-1 sm:space-x-2">
            <!-- <fuse-fullscreen class="hidden md:block"></fuse-fullscreen> -->
            <languages></languages>
            <tenant></tenant>
            <user>
                <ng-container *ngFor="let item of toolbarDropdownItems$ | async">
                    <mat-divider *ngIf="item.type === 'divider'"></mat-divider>
                    <button *ngIf="item.type === 'button'" mat-menu-item (click)="item.onClick()">
                        <mat-icon *ngIf="item.icon" class="icon" [svgIcon]="item.icon"></mat-icon>
                        {{ item.title }}
                    </button>
                    <div class="p-4" *ngIf="item.type === 'text'">
                        <span class="flex flex-col leading-none">
                            <span>{{ item.title }}</span>
                            <span class="mt-1.5 text-md font-medium">{{ item.value }}</span>
                        </span>
                    </div>
                </ng-container>
            </user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>
</div>
