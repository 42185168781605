<h2 *ngIf="heading" >{{ heading }}</h2>

<div class="description-container">
    <ng-container *ngFor="let item of items">
        <payments-description-list-item [label]="item.label" [value]="item.value"> </payments-description-list-item>
    </ng-container>

    <!-- In case the value is not simple plain text value then use projection instead -->
    <ng-content></ng-content>
</div>
