import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { filter, firstValueFrom, map, Observable, switchMap } from 'rxjs';
import { IdentityService } from '../identity-service';

@Injectable()
export class Auth0IdentityService implements IdentityService {
    domain: string;

    constructor(private authService: Auth0Service, @Inject('ENV') public env, protected http: HttpClient) {
        this.domain = env.CLIENT_MANAGER_SERVICE_URL;
    }

    isAuthenticated$ = this.authService.isAuthenticated$;

    user$ = this.authService.user$.pipe(
        filter(user => !!user),
        map((user) => {
            return {
                email: user.email,
                name: user.name,
                role: user['https://client-manager.ridango.com/organisation'].name,
                picture: user.picture,
                initials: user.name?.charAt(0),
            };
        })
    );

    currentTenant$ = this.authService.idTokenClaims$.pipe(
        map((claims) => {
            return claims['https://client-manager.ridango.com/tenantId'];
        })
    );

    tenants$ = this.authService.idTokenClaims$.pipe(
        map((claims) => {
            return claims['https://client-manager.ridango.com/tenants'];
        })
    );

    applications$ = this.authService.idTokenClaims$.pipe(
        map((claims) => {
            return claims['https://client-manager.ridango.com/applications'];
        })
    );

    setTenant(tenantId): Observable<any> {
        return this.authService.getAccessTokenSilently().pipe(
            switchMap((token) => {
                const headers = new HttpHeaders({
                    Authorization: `Bearer ${token}`,
                });

                return this.http
                    .patch(`${this.domain}/api/v1/me/tenant`, { tenantId }, { headers })
                    .pipe(switchMap(() => this.authService.getAccessTokenSilently({ ignoreCache: true })));
            })
        );
    }

    login(): void {
        this.authService.loginWithRedirect();
    }

    logout(): void {
        this.authService.logout({ returnTo: window.location.origin });
    }
}
