import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { RuiButtonModule } from '@ridango/ui/button';
import { LayoutModule } from '../layout/layout.module';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ModalComponent } from './modal/modal.component';

const components = [ModalComponent, ConfirmationComponent];

@NgModule({
    declarations: [...components],
    exports: [...components],
    imports: [CommonModule, MatDialogModule, MatButtonModule, TranslateModule, LayoutModule, RuiButtonModule],
})
export class ModalModule {}
