import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    RuiCardComponent, RuiCardHeaderComponent,
    RuiCardSubtitleComponent,
    RuiCardTitleComponent
} from './card.component';
import { FuseCardModule } from '../../@fuse/components/card';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
    imports: [
        CommonModule,
        FuseCardModule,
        MatDividerModule
    ],
    declarations: [
        RuiCardComponent,
        RuiCardHeaderComponent,
        RuiCardTitleComponent,
        RuiCardSubtitleComponent
    ],
    exports: [
        RuiCardComponent,
        RuiCardHeaderComponent,
        RuiCardTitleComponent,
        RuiCardSubtitleComponent
    ]
})
export class RuiCardModule {
}
