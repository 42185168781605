import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationComponent } from './notification/notification.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
    declarations: [NotificationComponent],
    imports: [CommonModule, MatIconModule, MatButtonModule, MatSnackBarModule],
    exports: [NotificationComponent],
})
export class NotificationsModule {}
