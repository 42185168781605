import { Component, Input, OnInit } from '@angular/core';
import { map, Observable, startWith } from 'rxjs';
import { FormGeneratorField } from '../form-generator.models';

@Component({
    selector: 'payments-form-autocomplete',
    templateUrl: './form-autocomplete.component.html',
    styleUrls: ['./form-autocomplete.component.scss'],
})
export class FormAutocompleteComponent implements OnInit {
    @Input() row: FormGeneratorField;

    filteredOptions: Observable<unknown[]>;

    ngOnInit() {
        this.filteredOptions = this.row?.control.valueChanges.pipe(
            startWith(''),
            map((value) => this._filter(value))
        );
    }

    private _filter(value) {
        if (value === null || value === undefined) return;
        const filterValue = (value += '').toLowerCase();
        return this.row.options?.filter((option) => {
            if (option === null || option === undefined) return false;
            return (option += '').toLowerCase().includes(filterValue);
        });
    }
}
