import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FormFieldElement, FormFieldType, FormGenerator } from '../form-generator.models';

@Component({
    selector: 'payments-form-generator',
    templateUrl: './form-generator.component.html',
    styleUrls: ['./form-generator.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FormGeneratorComponent {
    @Input() formGenerator: FormGenerator;

    formFieldType = FormFieldType;
    formFieldElement = FormFieldElement;
}
