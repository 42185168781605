import { QueryStringService } from './query-string.service';
import { IStorageService } from './storage.interface';
import { StorageService } from './storage.service';

export class StorageFactory {
    static get sessionStorage(): IStorageService {
        return new StorageService(sessionStorage);
    }

    static get localStorage(): IStorageService {
        return new StorageService(localStorage);
    }

    static get urlStorage(): IStorageService {
        return new QueryStringService();
    }
}
