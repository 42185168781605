<mat-form-field
    class="form-field"
    hideRequiredMarker
    appearance="fill">
    <mat-label>{{ row.label | translate | requiredAsterisk: row.control }}</mat-label>
    <input
        matInput
        [type]="row.type"
        [placeholder]="row.placeholder | translate"
        [formControl]="row.control"
        [minlength]="row.minLength"
        [maxlength]="row.maxLength"
        [min]="row.min"
        [max]="row.max"
        [matAutocomplete]="auto" />
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{ row.displayFn ? (row.displayFn(option) | translate) : option }}
        </mat-option>
    </mat-autocomplete>

    <mat-error *ngIf="row.control.invalid">
        {{ 'validationErrors.' + row.validationError | translate }}
    </mat-error>
</mat-form-field>
