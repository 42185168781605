import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RequiredAsteriskPipe } from './required-asterisk/required-asterisk.pipe';
import { SpacedNumberPipeModule } from './spaced-number/spaced-number.module';
import { TpsMoneyPipe } from './tps-money/tps-money.pipe';
import { PipesModule as PaymentsPipesModule } from '@payments';

const pipes = [TpsMoneyPipe, RequiredAsteriskPipe];

@NgModule({
    declarations: [...pipes],
    imports: [CommonModule, PaymentsPipesModule],
    exports: [...pipes, SpacedNumberPipeModule, PaymentsPipesModule],
})
export class PipesModule {}
