import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { FuseAlertModule } from '@ridango/ui/alert';
import { DirectivesModule } from '../../directives/directives.module';
import { PipesModule } from '../../pipes/pipes.module';
import { ModalModule } from '../modal/modal.module';
import { FormDiscardWithConfirmationComponent } from './form-discard-with-confirmation/form-discard-with-confirmation.component';
import { FormGeneratorInModalComponent } from './form-generator-in-modal/form-generator-in-modal.component';
import { FormGeneratorComponent } from './form-generator/form-generator.component';
import { FormAutocompleteComponent } from './form-autocomplete/form-autocomplete.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RuiButtonModule } from '@ridango/ui/button';

const components = [FormDiscardWithConfirmationComponent, FormGeneratorComponent, FormGeneratorInModalComponent];
@NgModule({
    declarations: [...components, FormAutocompleteComponent],
    exports: [...components],
    imports: [
        CommonModule,
        TranslateModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatButtonModule,
        ReactiveFormsModule,
        ModalModule,
        PipesModule,
        MatCheckboxModule,
        DirectivesModule,
        MatDatepickerModule,
        FuseAlertModule,
        MatAutocompleteModule,
        RuiButtonModule,
    ],
})
export class FormUtilsModule {}
