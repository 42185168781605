import { Component } from '@angular/core';

@Component({
    selector: 'payments-not-found',
    template: `<payments-exception
            icon="heroicons_outline:document-search"
            [title]="'common.pageNotFoundTitle' | translate"
            link="/"
            [linkDescription]="'common.pageNotFoundLinkDescription' | translate">
            </payments-exception>`,
    styles: [':host { display: block; width: 100%; max-width: 1248px; padding: 24px; margin: 0 auto; }']
})
export class NotFoundComponent {}
