import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CopyComponent } from './copy.component';

@NgModule({
    declarations: [CopyComponent],
    exports: [CopyComponent],
    imports: [MatIconModule],
})
export class CopyModule {}
