import { Inject, Injectable, InjectionToken, OnDestroy } from '@angular/core';
import { initialize, LDClient, LDFlagValue } from 'launchdarkly-js-client-sdk';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

/** Injection token that can be used to specify default snack bar. */
export const LAUNCHDARKLY_CLIENT_ID = new InjectionToken<string>('launchdarkly-client-id');

@Injectable({
    providedIn: 'root',
})
export class LaunchDarklyService implements OnDestroy {
    private ldClient: LDClient;
    private flagChange: Subject<boolean> = new BehaviorSubject(true);

    constructor(@Inject(LAUNCHDARKLY_CLIENT_ID) private client_id) {
        this.ldClient = initialize(client_id, { anonymous: true });

        this.ldClient.on('change', () => {
            this.flagChange.next(true);
        });

        this.ldClient.on('ready', () => {
            this.flagChange.next(true);
        });
    }

    getFlag(flagName: string, defaultValue?): Observable<LDFlagValue> {
        return this.flagChange.pipe(
            //
            map(() => this.ldClient.variation(flagName, defaultValue)),
            distinctUntilChanged()
        );
    }

    ngOnDestroy(): void {
        this.ldClient.close();
    }
}
