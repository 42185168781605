import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IdentityService } from '@ridango/auth';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user',
})
export class UserComponent {
    constructor(public identityService: IdentityService) {}
}
