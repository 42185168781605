import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FuseConfigService } from '@fuse/services/config';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalRouteGuardService, PendingChangesGuard } from '@payments';
import { CustomPaginatorIntl } from '@payments/list-manager';
import { compiler } from '@ridango/i18n';
import { LOGOS } from '@ridango/ui/asset-provider/asset-provider';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { CoreModule } from './core/core.module';
import loader from './translation-loader';
import { SnackbarModule } from './widgets/snackbar/snackbar.module';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        CoreModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            compiler,
            loader,
        }),

        SnackbarModule,
    ],
    providers: [
        { provide: LOGOS, useValue: 'sfey' },
        { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl },
        PendingChangesGuard,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(
        private fuseConfigService: FuseConfigService,
        private globalRouteGuardService: GlobalRouteGuardService
    ) {
        this.fuseConfigService.config = { theme: 'theme-sfey' };
        this.globalRouteGuardService.ensureGlobalGuards();
    }
}
