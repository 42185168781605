import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class ApiBaseurlInterceptor implements HttpInterceptor {
    private readonly excludedUrls = ['assets', environment.TPS_AUTH_URL];

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (this.excludedUrls.some((url) => request.url.includes(url))) {
            return next.handle(request);
        }

        const baseUrl: string = environment?.API_URL ?? '';
        const url = `${baseUrl}${request.url}`;
        return next.handle(request.clone({ url }));
    }
}
