import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { AuthIdentityService, AuthModule, IdentityService } from '@ridango/auth';
import { I18nModule } from '@ridango/i18n';
import { LAUNCHDARKLY_CLIENT_ID } from '@ridango/launchdarkly';
import { FuseCoreModule } from '@ridango/ui/fuse-core';
import { FuseLayoutModule } from '@ridango/ui/fuse-layout';
import { QuillModule } from 'ngx-quill';
import { environment } from '../../environments/environment';
import { ApiBaseurlInterceptor } from '../interceptors/api-baseurl.interceptor';
import { ApiErrorInterceptor } from '../interceptors/api-error.interceptor';
import { AfterLoginComponent } from './after-login.component';
import authModuleConfig from './auth-module.config';
import { AuthorizationModule } from './authorization/authorization.module';
import { ChangeRolesModule } from './change-roles/change-roles.module';
import { NotFoundModule } from './not-found/not-found.module';
import paginatorDefaults from './paginator-defaults';
import quillModuleConfig from './quill/quill-module-config';
import { UserActivityModule } from './user-activity/user-activity.module';

@NgModule({
    declarations: [AfterLoginComponent],
    imports: [
        MatSelectModule /* Provide mat-select-scroll-strategy injection token */,
        ChangeRolesModule,
        UserActivityModule,
        NotFoundModule,
        QuillModule.forRoot(quillModuleConfig),
        AuthModule.forRoot(authModuleConfig),
    ],
    exports: [AuthorizationModule, AuthModule, FuseCoreModule, FuseLayoutModule, I18nModule],
    providers: [
        { provide: IdentityService, useClass: AuthIdentityService },
        { provide: HTTP_INTERCEPTORS, useClass: ApiErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ApiBaseurlInterceptor, multi: true },
        { provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: paginatorDefaults },
        {
            provide: LAUNCHDARKLY_CLIENT_ID,
            useValue: environment.LAUNCHDARKLY_CLIENT_ID,
        },
    ],
})
export class CoreModule {}
