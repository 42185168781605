import { Observable } from 'rxjs';

export abstract class IdentityService {
    user$: Observable<IdentityServiceUser>;
    isAuthenticated$: Observable<boolean>;

    abstract login(): void;
    abstract logout(): void;
}

export interface IdentityServiceUser {
    name: string;
    role?: string;
    email: string;
    picture?: string | null;
    initials: string;
}
