import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { UserComponent } from './user.component';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
    declarations: [UserComponent],
    imports: [CommonModule, MatButtonModule, MatIconModule, MatMenuModule, MatDividerModule],
    exports: [UserComponent],
})
export class UserModule {}
