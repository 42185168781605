import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SharedModule } from '../../shared/shared.module';
import { ModalModule } from '../../widgets/modal/modal.module';
import { ChangeRolesComponent } from './change-roles/change-roles.component';

@NgModule({
    declarations: [ChangeRolesComponent],
    exports: [ChangeRolesComponent],
    imports: [CommonModule, SharedModule, ModalModule, MatAutocompleteModule],
})
export class ChangeRolesModule {}
