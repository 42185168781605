import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseLoadingService } from '@fuse/services/loading';
import { Subscription } from 'rxjs';

export interface IConfirmationComponentData {
    title?: string;
    question: string;
    actionCallback: () => void;
    yesButtonText?: string;
    yesButtonColor?: ThemePalette;
    noButtonText?: string;
    noButtonColor?: ThemePalette;
    closeModalAction?: () => void;
}

@Component({
    selector: 'payments-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit, OnDestroy {
    title = this.data?.title ?? 'common.confirmTitle';
    question = this.data?.question;

    yesButtonText = this.data?.yesButtonText ?? 'common.yes';
    yesButtonColor: ThemePalette = this.data?.yesButtonColor ?? 'primary';

    noButtonText = this.data?.noButtonText ?? 'common.no';
    noButtonColor: ThemePalette = this.data?.noButtonColor ?? undefined;

    loading = false;
    private subscription: Subscription;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: IConfirmationComponentData,
        private loadingService: FuseLoadingService
    ) {}

    ngOnInit(): void {
        this.subscription = this.loadingService.show$.subscribe(show => {
            if (!show) {
                this.loading = false;
            }
        })
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onNoClick(): void {
        this.close();
    }

    onYesClick(): void {
        this.loading = true;
        this.data.actionCallback();
    }

    private close() {
        if (this.data?.closeModalAction) {
            this.data?.closeModalAction();
        }
    }
}
