import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FuseFullscreenModule } from '../../../../../@fuse/components/fullscreen';
import { FuseNavigationModule } from '../../../../../@fuse/components/navigation';
import { LanguagesModule } from '../../../common/languages/languages.module';
import { DenseLayoutComponent } from './dense.component';
import { CommonModule } from '@angular/common';
import { UserModule } from '../../../common/user/user.module';
import { TenantModule } from '../../../common/tenant/tenant.module';

@NgModule({
    declarations: [DenseLayoutComponent],
    imports: [
        CommonModule,
        HttpClientModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        FuseFullscreenModule,
        FuseNavigationModule,
        LanguagesModule,
        UserModule,
        TenantModule,
    ],
    exports: [DenseLayoutComponent],
})
export class DenseLayoutModule {}
