import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NavigationHistoryService {
    private _routeHistroy = [];

    constructor(private router: Router) {}

    init() {
        this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((e) => {
            this._routeHistroy.push((e as NavigationEnd).url);
        });
    }

    get previousRoute() {
        return this._routeHistroy[this._routeHistroy.length - 2];
    }

    get isFirstRoute() {
        return this._routeHistroy.length === 1;
    }
}
