import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { AuthorizationService } from './authorization.service';

@Injectable({
    providedIn: 'root',
})
export class AuthorizedPreloadingStrategy implements PreloadingStrategy {
    constructor(private authorizationService: AuthorizationService) {}

    preload(route: Route, load: () => Observable<unknown>): Observable<unknown> {
        if (route?.data?.roles) {
            return this.authorizationService.hasSomeRole$(route.data.roles).pipe(
                concatMap((hasSomeRole) => {
                    return hasSomeRole ? load() : of(null);
                })
            );
        }
        return load();
    }
}
