import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ModalService } from '../modal/modal.service';
import { SnackbarService } from '../snackbar/snackbar.service';

@Injectable()
export class ActionsHelperService {
    update$ = new Subject();

    constructor(public modalService: ModalService, private notificationService: SnackbarService) {}

    confirm(action: Observable<unknown>, question: string, successMsg: string) {
        const confirm$ = new Subject();
        this.modalService.openConfirm({
            question,
            actionCallback: () => this.handleActionRequest(action, successMsg, confirm$),
        });

        return confirm$;
    }

    delete(action: Observable<unknown>, question: string, successMsg: string) {
        const delete$ = new Subject();
        this.modalService.openDelete({
            question,
            actionCallback: () => this.handleActionRequest(action, successMsg, delete$),
        });

        return delete$;
    }

    private handleActionRequest(stream: Observable<unknown>, successMsg: string, action$: Subject<unknown>) {
        stream.subscribe({
            next: () => {
                this.notificationService.addSuccess(successMsg);
                this.modalService.close();
                this.update$.next(null);
                action$.next(null);
            },
        });
    }
}
