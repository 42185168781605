import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ModalModule } from '../modal/modal.module';
import { ActionsHelperService } from './actions-helper.service';
import { ActionsWrapperComponent } from './actions-wrapper/actions-wrapper.component';

@NgModule({
    declarations: [ActionsWrapperComponent],
    exports: [ActionsWrapperComponent],
    imports: [CommonModule, ModalModule, MatMenuModule, MatIconModule, MatButtonModule],
    providers: [ActionsHelperService],
})
export class ActionsModule {}
