import { Component, Input } from '@angular/core';

@Component({
    selector: 'payments-exception',
    templateUrl: './exception.component.html',
    styleUrls: ['./exception.component.scss'],
})
export class ExceptionComponent {
    @Input() title: string;
    @Input() icon: string;
    @Input() link: string;
    @Input() linkDescription: string;
}
