import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from '../../widgets/modal/modal.module';
import { SessionExpirationComponent } from './session-expiration/session-expiration.component';
import { UserActivityInterceptor } from './user-activity.interceptor';

@NgModule({
    declarations: [SessionExpirationComponent],
    imports: [CommonModule, ModalModule, TranslateModule, MatButtonModule],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: UserActivityInterceptor, multi: true }],
})
export class UserActivityModule {}
