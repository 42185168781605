import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from './core/app-state.service';
import { NavigationGenerator } from './navigation.generator';
import { AuthService } from '@ridango/auth';
import { StorageFactory } from '@payments/list-manager';
import { AuditTrailService } from './services/audit-trail/audit-trail.service';
import { Router } from '@angular/router';

@Component({
    selector: 'payments-root',
    template: `
        <router-outlet></router-outlet>
        <payments-snackbar-outlet></payments-snackbar-outlet>
    `,
    styles: [':host { display: flex; flex: 1 1 auto; width: 100%; height: 100%;}'],
})
export class AppComponent {
    constructor(
        private router: Router,
        private authService: AuthService,
        private appStateService: AppStateService,
        private navigationGenerator: NavigationGenerator,
        private translate: TranslateService,
        private titleService: Title,
        private auditTrailService: AuditTrailService
    ) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;

        this.navigationGenerator.generate();

        this.translate.onLangChange.subscribe(() => {
            this.titleService.setTitle(this.translate.instant('common.appName'));
        });

        this.appStateService.init();

        this.authService.beforeLogOut$.subscribe(() => {
            this.auditTrailService.logout().subscribe(() => {
                StorageFactory.sessionStorage.clear();
                StorageFactory.urlStorage.clear();
            });
        });
    }
}
