import { Component, Input, OnInit } from '@angular/core';
import { timer } from 'rxjs';
import { SnackbarInfo } from '../snackbar.model';

@Component({
    selector: 'payments-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent implements OnInit {
    @Input() notification: SnackbarInfo;
    animationStyle = '';

    ngOnInit(): void {
        if (this.notification?.timeout) {
            timer(this.notification.timeout).subscribe(() => {
                this.close();
            });
        }
        setTimeout(() => (this.animationStyle = 'transform: scale(1); opacity: 1;'), 0);
    }

    close() {
        this.notification.display = false;
    }
}
