import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { BlockComponent } from './block/block.component';
import { CodeBlockComponent } from './code-block/code-block.component';
import { LoadingSectionComponent } from './loading-section/loading-section.component';
import { NoResultsComponent } from './no-results/no-results.component';
import { SectionComponent } from './section/section.component';
import { TableLoaderComponent } from './table-loader/table-loader.component';
import { TitleContainerComponent } from './title-container/title-container.component';

const components = [
    SectionComponent,
    BlockComponent,
    TableLoaderComponent,
    LoadingSectionComponent,
    TitleContainerComponent,
    NoResultsComponent,
    CodeBlockComponent,
];

@NgModule({
    declarations: [...components],
    exports: [...components],
    imports: [CommonModule, MatProgressSpinnerModule, TranslateModule],
})
export class LayoutModule {}
