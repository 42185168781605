<div class="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:p-8">
    <div class="flex md:w-full md:max-w-6xl sm:rounded  sm:shadow overflow-hidden sm:bg-card">
        <div
            class="
                relative
                hidden
                md:flex
                flex-auto
                items-center
                justify-center
                h-full
                p-16
                lg:px-28
                overflow-hidden
                bg-gray-800
                dark:border-r
            "
            style="
                background-image: url('https://ridango.com/wp-content/uploads/2020/04/ridango-public-transport-solutions.jpg');
                background-position: right;
            ">
            <div class="z-10 relative w-full max-w-2xl">
                <div class="text-6xl font-bold leading-none text-gray-100">
                    <div>We are your one-way ticket to a world of <span class="text-link-500">contactless transportation</span></div>
                </div>
            </div>
        </div>
        <div class="w-full sm:w-auto py-8 px-4 sm:p-12 md:p-16">
            <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
                <!-- Logo -->
                <img alt="Logo image" class="h-13" src="assets/rui/logos/SFEY_Logo_RGB_GB.svg" />
                <!-- Submit button -->
                <button
                    (click)="signIn()"
                    [color]="'accent'"
                    class="fuse-mat-button-large w-full mt-6 text-white"
                    mat-flat-button>
                    Sign in
                </button>
            </div>
        </div>
    </div>
</div>
