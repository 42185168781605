import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { Page } from '../common.models';
import {
    AdminCompany,
    AdminCompanyTsUrl,
    CompanyProperty,
    CompanyQueryParams,
    CompanyRequest,
    Merchant,
    SendValidatorParametersBatchRequest,
} from './companies.models';

@Injectable({
    providedIn: 'root',
})
export class CompaniesService {
    constructor(private http: HttpClient) {}

    getAllowed() {
        return this.http.get<AdminCompany[]>(`/admin/companies`);
    }

    get(params: Partial<CompanyQueryParams>) {
        const url = `/admin/companies`;
        return this.http.get<Page<AdminCompany>>(url, { params });
    }

    getOne(companyId: number) {
        return this.http.get<AdminCompany>(`/admin/companies/${companyId}`);
    }

    update(companyId: number, body: CompanyRequest) {
        return this.http.put<void>(`/admin/companies/${companyId}`, body);
    }

    create(body: CompanyRequest) {
        return this.http.post<void>(`/admin/companies`, body);
    }

    delete(companyId: number) {
        return this.http.delete<void>(`/admin/companies/${companyId}`);
    }

    getPosProperties(companyId: number) {
        return this.http.get<CompanyProperty[]>(`/admin/company/${companyId}/pos/properties`);
    }

    getCompanyProperties(companyId: number) {
        return this.http.get<CompanyProperty[]>(`/admin/company/${companyId}/properties`);
    }

    getCompanyProperty(companyId: number, key: string) {
        return this.getCompanyProperties(companyId).pipe(
            map((properties) => properties.find((property) => property.key === key))
        );
    }

    editCompanyProperty(companyId: number, body: CompanyProperty[]) {
        const url = `/admin/company/${companyId}/properties`;
        return this.http.put<void>(url, body);
    }

    editCompanyPosProperty(companyId: number, body: CompanyProperty[]) {
        const url = `/admin/company/${companyId}/pos/properties`;
        return this.http.put<void>(url, body);
    }

    sendValidatorParams(companyId: number) {
        return this.http.post<void>(`/tms/validators/company/${companyId}/send-parameters`, {});
    }

    sendValidatorParamsBatch(companyId: number, body: SendValidatorParametersBatchRequest) {
        return this.http.post<void>(`/tms/validators/company/${companyId}/send-parameters/batch`, body);
    }
    startACQ(companyId: number) {
        return this.http.post<void>(`/admin/parameter/download/company/${companyId}`, {});
    }

    startTransitEOD(companyId: number) {
        return this.http.post<void>(`/admin/transit-eod/start/company/${companyId}`, {});
    }

    getCompanyUrls(companyId: number) {
        const url = `/admin/company/${companyId}/properties/tsurls`;
        return this.http.get<AdminCompanyTsUrl[]>(url);
    }

    getTimezones() {
        const url = `/admin/companies/timezones`;
        return this.http.get<string[]>(url);
    }

    getMerchants() {
        const url = `/admin/merchants`;
        return this.http.get<Merchant[]>(url);
    }
}
