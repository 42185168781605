import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'payments-snackbar-outlet',
    templateUrl: './snackbar-outlet.component.html',
    styleUrls: ['./snackbar-outlet.component.scss'],
})
export class SnackbarOutletComponent {
    notifications$ = new BehaviorSubject([]);
}
