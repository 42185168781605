export enum EAppRoutes {
    Root = '/',
    AfterAuth = 'auth/login',
    Id = 'id',
    New = 'new',
    Edit = 'edit',
    About = 'about',
    Active = 'active',
    Debt = 'debt',
    All = 'all',
    Audit = 'audit',
    Cards = 'cards',
    Dashboard = 'dashboard',
    Organizations = 'organizations',
    CurrentOrganization = 'current',
    CompanyId = 'companyId',
    ValidatorParameters = 'validator-parameters',
    ValidatorParameterId = 'validatorParameterId',
    ValidatorKeyId = 'validatorKeyId',
    Pos = 'pos',
    Transactions = 'transactions',
    Validators = 'validators',
    Notifications = 'notifications',
    Acquirers = 'acquirers',
    Currencies = 'currencies',
    Merchants = 'merchants',
    Jobs = 'jobs',
    LiabilityRegions = 'liability-regions',
    Properties = 'properties',
    Regions = 'regions',
    Schemas = 'schemas',
    Settings = 'settings',
    Templates = 'templates',
}

export enum Breadcrumbs {
    Home,
    Dashboard,
    About,
    ActiveCards,
    DebtCards,
    AllCards,
    Audit,
    CardDetails,
    OrganizationDetails,
    Organizations,
    POS,
    POSDetails,
    TransactionDetails,
    Transactions,
    ValidatorDetails,
    Validators,
    Notifications,
    Acquirers,
    AcquirerDetails,
    Currencies,
    Merchants,
    Jobs,
    LiabilityRegionDetails,
    LiabilityRegions,
    Properties,
    Regions,
    Schemas,
    Templates,
}

export interface IBreadcrumbConfig {
    crumb: Breadcrumbs;
    link?: EAppRoutes[];
    title: string;
}

export const breadcrumbConfig: IBreadcrumbConfig[] = [
    {
        crumb: Breadcrumbs.Home,
        link: [EAppRoutes.Root, EAppRoutes.Dashboard],
        title: 'common.breadcrumbs.home',
    },
    {
        crumb: Breadcrumbs.Dashboard,
        link: [EAppRoutes.Root, EAppRoutes.Dashboard],
        title: 'common.menu.dashboard',
    },
    {
        crumb: Breadcrumbs.About,
        link: [EAppRoutes.Root, EAppRoutes.About],
        title: 'common.menu.about',
    },
    {
        crumb: Breadcrumbs.ActiveCards,
        link: [EAppRoutes.Root, EAppRoutes.Cards, EAppRoutes.Active],
        title: 'common.menu.activeCards',
    },
    {
        crumb: Breadcrumbs.DebtCards,
        link: [EAppRoutes.Root, EAppRoutes.Cards, EAppRoutes.Debt],
        title: 'common.menu.debtCards',
    },
    {
        crumb: Breadcrumbs.AllCards,
        link: [EAppRoutes.Root, EAppRoutes.Cards, EAppRoutes.All],
        title: 'common.menu.allCards',
    },
    {
        crumb: Breadcrumbs.Audit,
        link: [EAppRoutes.Root, EAppRoutes.Audit],
        title: 'common.menu.audit',
    },
    {
        crumb: Breadcrumbs.CardDetails,
        title: 'common.breadcrumbs.cardDetails',
    },
    {
        crumb: Breadcrumbs.OrganizationDetails,
        title: 'common.breadcrumbs.companyDetails',
    },
    {
        crumb: Breadcrumbs.Organizations,
        link: [EAppRoutes.Root, EAppRoutes.Organizations],
        title: 'common.menu.company',
    },
    {
        crumb: Breadcrumbs.POS,
        link: [EAppRoutes.Root, EAppRoutes.Pos],
        title: 'common.menu.pos',
    },
    {
        crumb: Breadcrumbs.POSDetails,
        title: 'common.breadcrumbs.posDetails',
    },
    {
        crumb: Breadcrumbs.TransactionDetails,
        title: 'common.breadcrumbs.transactionDetails',
    },
    {
        crumb: Breadcrumbs.Transactions,
        link: [EAppRoutes.Root, EAppRoutes.Transactions],
        title: 'common.menu.transactions',
    },
    {
        crumb: Breadcrumbs.ValidatorDetails,
        title: 'common.breadcrumbs.validatorDetails',
    },
    {
        crumb: Breadcrumbs.Validators,
        link: [EAppRoutes.Root, EAppRoutes.Validators],
        title: 'common.menu.validators',
    },
    {
        crumb: Breadcrumbs.Notifications,
        title: 'common.breadcrumbs.notifications',
    },
    {
        crumb: Breadcrumbs.Acquirers,
        link: [EAppRoutes.Root, EAppRoutes.Settings, EAppRoutes.Acquirers],
        title: 'common.menu.acquirers',
    },
    {
        crumb: Breadcrumbs.AcquirerDetails,
        title: 'common.breadcrumbs.acquirerDetails',
    },
    {
        crumb: Breadcrumbs.Currencies,
        link: [EAppRoutes.Root, EAppRoutes.Settings, EAppRoutes.Currencies],
        title: 'common.menu.currencies',
    },
    {
        crumb: Breadcrumbs.Merchants,
        link: [EAppRoutes.Root, EAppRoutes.Settings, EAppRoutes.Merchants],
        title: 'common.menu.merchants',
    },
    {
        crumb: Breadcrumbs.Jobs,
        link: [EAppRoutes.Root, EAppRoutes.Settings, EAppRoutes.Jobs],
        title: 'common.menu.jobs',
    },
    {
        crumb: Breadcrumbs.LiabilityRegionDetails,
        title: 'common.breadcrumbs.liabilityRegionDetails',
    },
    {
        crumb: Breadcrumbs.LiabilityRegions,
        link: [EAppRoutes.Root, EAppRoutes.Settings, EAppRoutes.LiabilityRegions],
        title: 'common.menu.liabilityRegions',
    },
    {
        crumb: Breadcrumbs.Properties,
        link: [EAppRoutes.Root, EAppRoutes.Settings, EAppRoutes.Properties],
        title: 'common.menu.properties',
    },
    {
        crumb: Breadcrumbs.Regions,
        link: [EAppRoutes.Root, EAppRoutes.Settings, EAppRoutes.Regions],
        title: 'common.menu.regions',
    },
    {
        crumb: Breadcrumbs.Schemas,
        link: [EAppRoutes.Root, EAppRoutes.Settings, EAppRoutes.Schemas],
        title: 'common.menu.schemas',
    },
    {
        crumb: Breadcrumbs.Templates,
        link: [EAppRoutes.Root, EAppRoutes.Settings, EAppRoutes.Templates],
        title: 'common.menu.templates',
    },
];
