<form class="generator" [formGroup]="formGenerator?.form" *ngIf="formGenerator">
    <div class="row row--{{ row?.class }}" *ngFor="let row of formGenerator?.fields">
        <!-- Heading -->
        <h2 *ngIf="row.element === formFieldElement?.Heading">{{ row.label | translate }}</h2>

        <!-- Input -->
        <mat-form-field
            class="form-field"
            hideRequiredMarker
            *ngIf="row.element === formFieldElement?.Input"
            appearance="fill">
            <mat-label>{{
                row.label | translate | requiredAsterisk: formGenerator?.form.controls[row.name]
            }}</mat-label>
            <input
                matInput
                [type]="row.type"
                [placeholder]="row.placeholder | translate"
                [formControlName]="row.name"
                [minlength]="row.minLength"
                [maxlength]="row.maxLength"
                [min]="row.min"
                [max]="row.max"
                class="{{ row.inputClass }}"/>
            <mat-error *ngFor="let error of formGenerator?.form.controls[row.name].errors | keyvalue">
                {{ 'validationErrors.' + error.key | translate: { value: error.value } }}
            </mat-error>
        </mat-form-field>

        <!-- Select / Multiselect -->
        <mat-form-field
            class="form-field"
            hideRequiredMarker
            *ngIf="row.element === formFieldElement?.Select || row.element === formFieldElement?.SelectMultiple"
            appearance="fill">
            <mat-label>{{
                row.label | translate | requiredAsterisk: formGenerator?.form.controls[row.name]
            }}</mat-label>
            <mat-select
                matInput
                [formControlName]="row.name"
                [multiple]="row.element === formFieldElement?.SelectMultiple">
                <!-- Add a default "None" to select unless multiselect -->
                <mat-option *ngIf="row.element !== formFieldElement?.SelectMultiple">{{
                    'common.none' | translate
                }}</mat-option>
                <mat-option *ngFor="let option of row.options" [value]="option">
                    {{ row.displayFn ? (row.displayFn(option) | translate) : option }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="formGenerator.form.controls[row.name].invalid">
                {{ 'validationErrors.' + row.validationError | translate }}
            </mat-error>
        </mat-form-field>

        <!-- Checkbox -->
        <div class="mat-form-field form-field checkbox" *ngIf="row.element === formFieldElement?.Checkbox">
            <mat-checkbox [formControlName]="row.name">{{
                row.label | translate | requiredAsterisk: formGenerator?.form.controls[row.name]
            }}</mat-checkbox>
        </div>

        <!-- Datepicker -->
        <mat-form-field
            class="form-field"
            hideRequiredMarker
            *ngIf="row.element === formFieldElement?.Datepicker"
            appearance="fill">
            <mat-label>{{
                row.label | translate | requiredAsterisk: formGenerator?.form.controls[row.name]
            }}</mat-label>
            <input matInput [matDatepicker]="picker" [formControlName]="row.name" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="formGenerator.form.controls[row.name].invalid">
                {{ 'validationErrors.' + row.validationError | translate }}
            </mat-error>
        </mat-form-field>

        <!-- Autocomplete -->
        <payments-form-autocomplete
            [row]="row"
            *ngIf="row.element === formFieldElement?.Autocomplete"></payments-form-autocomplete>
    </div>
</form>
