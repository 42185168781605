import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LoadingButtonComponent, LoadingDataComponent } from './loading/loading.component';

@NgModule({
    declarations: [LoadingButtonComponent, LoadingDataComponent],
    exports: [LoadingButtonComponent, LoadingDataComponent],
    imports: [CommonModule, NgxSkeletonLoaderModule],
})
export class LoadingModule {}
