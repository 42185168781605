import { AbstractControlOptions, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
export interface IFormGeneratorField {
    name: string;
    element: FormFieldElement;
    control: UntypedFormControl;
    label: string;

    class?: string;
    inputClass?: string;
    type?: FormFieldType;
    options?: unknown[];
    displayFn?: (option) => string;
    id?: string;
    placeholder?: string;
    minLength?: number;
    maxLength?: number;
    min?: number;
    max?: number;
}

export enum FormFieldType {
    Text = 'text',
    Email = 'email',
    Password = 'password',
    Number = 'number',
    Time = 'time',
}

export enum FormFieldElement {
    Input = 'input',
    Select = 'select',
    SelectMultiple = 'selectMultiple',
    Heading = 'heading',
    Checkbox = 'checkbox',
    Datepicker = 'datepicker',
    Autocomplete = 'autocomplete',
}

export class FormGenerator {
    group: UntypedFormGroup;
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null;
    fields: FormGeneratorField[];

    constructor(fields: IFormGeneratorField[], validatorOrOpts = null) {
        this.fields = fields.map((attrs) => new FormGeneratorField(attrs));
        this.validatorOrOpts = validatorOrOpts;
        this.group = this.createGroup();
        return this;
    }

    private createGroup(): UntypedFormGroup {
        const fields = this.fields.reduce((all, field) => ({ ...all, [field.name]: field?.control }), {});
        return new UntypedFormGroup(fields, this.validatorOrOpts);
    }

    get form(): UntypedFormGroup {
        return this.group;
    }
}

export class FormGeneratorField implements IFormGeneratorField {
    name: string;
    element: FormFieldElement;
    control: UntypedFormControl;
    label: string;

    class?: string;
    inputClass?: string;
    type?: FormFieldType;
    options?: unknown[];
    displayFn?: (option) => string;
    id?: string;
    placeholder?: string;
    minLength?: number;
    maxLength?: number;
    min?: number;
    max?: number;

    constructor(attributes: IFormGeneratorField) {
        this.name = attributes.name;
        this.element = attributes.element;
        this.control = attributes.control;
        this.label = attributes.label;

        this.class = attributes.class;
        this.inputClass = attributes.inputClass;
        this.type = attributes.type;
        this.options = attributes.options;
        this.displayFn = attributes.displayFn;
        this.id = attributes.id;
        this.placeholder = attributes.placeholder;
        this.minLength = attributes.minLength;
        this.maxLength = attributes.maxLength;
        this.min = attributes.min;
        this.max = attributes.max;
    }

    get validationError() {
        if (!this.control || !this.control.errors) return;

        return Object.keys(this.control.errors)[0];
    }
}
