import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { Navigation, ToolbarDropdownItem } from './navigation.types';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private _toolbarDropdownItems = new BehaviorSubject<ToolbarDropdownItem[]>([]);
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    get toolbarDropdownItems$(): Observable<ToolbarDropdownItem[]> {
        return this._toolbarDropdownItems.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    setItems(navigation: Navigation): void {
        this._navigation.next(navigation);
    }

    setToolbarDropdownItems(options: ToolbarDropdownItem[]) {
        this._toolbarDropdownItems.next(options);
    }
}
