import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from './not-found.component';
import { ExceptionsModule } from '../../widgets/exceptions/exceptions.module';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutModule } from '../../widgets/layout/layout.module';

@NgModule({
    declarations: [NotFoundComponent],
    imports: [CommonModule, LayoutModule, ExceptionsModule, TranslateModule],
})
export class NotFoundModule {}
