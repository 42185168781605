import { TranslateService } from '@ngx-translate/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class PaginatorI18n implements MatPaginatorIntl {
    constructor(private readonly translate: TranslateService) {
        this.translate.onLangChange.subscribe(() => {
            this.updateTranslations();
            this.changes.next();
        });
    }

    changes = new Subject<void>();

    itemsPerPageLabel = this.translate.instant('portal.pagination.items_per_page_label');
    nextPageLabel = this.translate.instant('portal.pagination.next_page_label');
    previousPageLabel = this.translate.instant('portal.pagination.previous_page_label');
    firstPageLabel = this.translate.instant('portal.pagination.first_page_label');
    lastPageLabel = this.translate.instant('portal.pagination.last_page_label');

    getRangeLabel(page: number, pageSize: number, length: number): string {
        if (length === 0 || pageSize === 0) {
            return this.translate.instant('portal.pagination.range_page_label_1', { length });
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return this.translate.instant('portal.pagination.range_page_label_2', {
            startIndex: startIndex + 1,
            endIndex,
            length,
        });
    }

    private updateTranslations() {
        this.itemsPerPageLabel = this.translate.instant('portal.pagination.items_per_page_label');
        this.nextPageLabel = this.translate.instant('portal.pagination.next_page_label');
        this.previousPageLabel = this.translate.instant('portal.pagination.previous_page_label');
        this.firstPageLabel = this.translate.instant('portal.pagination.first_page_label');
        this.lastPageLabel = this.translate.instant('portal.pagination.last_page_label');
    }
}
