import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '@ridango/auth';

@Component({
    templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
    signInForm!: UntypedFormGroup;
    showAlert!: boolean;

    constructor(private _authService: AuthService, private _formBuilder: UntypedFormBuilder) {
    }

    ngOnInit(): void {
        // Create the form
        this.signInForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
            rememberMe: ['']
        });
    }

    signIn(): void {
        this._authService.authenticate();
    }
}
