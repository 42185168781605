/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { AuthService } from '@ridango/auth';
import { AuthActiveRole } from 'libs/portal/auth/src/lib/auth.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { Role, RolesResponse } from '../../services/roles/roles.models';
import { RolesService } from '../../services/roles/roles.service';
import { ERoles } from './roles.enum';

@Injectable({
    providedIn: 'root',
})
export class AuthorizationService {
    private _availableRoles$ = new BehaviorSubject<RolesResponse>(null);
    private authActiveRole!: AuthActiveRole | undefined;

    constructor(private authService: AuthService, private rolesService: RolesService) {
        this.authService.session$.subscribe((session) => {
            this.authActiveRole = session?.resource?.activeRole;
            this.getAvailableRoles();
        });
    }

    get availableRoles$(): Observable<Role[]> {
        return this._availableRoles$.asObservable().pipe(
            filter((data) => !!data),
            map((data) => data.roles)
        );
    }

    get activeRole$(): Observable<Role> {
        return this.authService.session$.pipe(
            switchMap((session) =>
                this._availableRoles$.asObservable().pipe(
                    filter((data) => !!data),
                    take(1),
                    map((available) => {
                        return available?.roles.find(
                            (availableRole) => availableRole.id === session?.resource.activeRole.id
                        );
                    })
                )
            )
        );
    }

    get activeCompany$(): Observable<string> {
        return this.authService.session$.pipe(map((session) => session.resource.activeRole.organization.clientId));
    }

    private get authActiveRole$() {
        return this.authService.session$.pipe(map((session) => session?.resource?.activeRole));
    }

    hasRole(authorizedRole: ERoles, activeRole: AuthActiveRole = this.authActiveRole): boolean {
        return activeRole?.role?.clientId.includes(authorizedRole) ?? false;
    }

    hasSomeRole(authorizedRoles: ERoles[], activeRole: AuthActiveRole = this.authActiveRole): boolean {
        return authorizedRoles.some((authorizedRole) => this.hasRole(authorizedRole, activeRole));
    }

    hasSomeRole$(authorizedRoles: ERoles[]): Observable<boolean> {
        return this.authActiveRole$.pipe(
            take(1),
            map((activeRole) => this.hasSomeRole(authorizedRoles, activeRole))
        );
    }

    private getAvailableRoles() {
        this.rolesService.getRoles().subscribe((available) => this._availableRoles$.next(available));
    }
}
