import { Component, Input } from '@angular/core';

@Component({
    selector: 'payments-block',
    templateUrl: './block.component.html',
    styleUrls: ['./block.component.scss'],
})
export class BlockComponent {
    @Input() loading = false;
}
