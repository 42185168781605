import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import {
    RuiPageActions,
    RuiPageComponent,
    RuiPageContent,
    RuiPageDrawer,
    RuiPageFooter,
    RuiPageHeading,
    RuiPageTopnav,
} from './page.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild([]),
        NgxSkeletonLoaderModule,
        MatIconModule,
        MatButtonModule,
        MatSidenavModule,
    ],
    declarations: [
        RuiPageComponent,
        RuiPageHeading,
        RuiPageActions,
        RuiPageContent,
        RuiPageDrawer,
        RuiPageTopnav,
        RuiPageFooter,
    ],
    exports: [
        RuiPageComponent,
        RuiPageHeading,
        RuiPageActions,
        RuiPageContent,
        RuiPageDrawer,
        RuiPageTopnav,
        RuiPageFooter,
    ],
})
export class RuiPageModule {}
