import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, GuardsCheckStart, Route, Router } from '@angular/router';
import { PendingChangesGuard } from './pending-changes.guard';

@Injectable({ providedIn: 'root' })
export class GlobalRouteGuardService {
    constructor(private router: Router) {}

    /** This will help ensure that routes are automatically protected by ensuring that global guards are applied to every route */
    public ensureGlobalGuards() {
        this.router.events.subscribe((e: any) => {
            if (e instanceof GuardsCheckStart) {
                let lastChild: ActivatedRouteSnapshot = e.state.root;
                while (lastChild && lastChild.firstChild) {
                    lastChild = lastChild.firstChild;
                }

                if (lastChild && lastChild.routeConfig) {
                    this.ensureCanDeactivateGuards(lastChild.routeConfig);
                }
            }
        });
    }

    private ensureCanDeactivateGuards(routeConfig: Route) {
        if (!routeConfig.canDeactivate) {
            routeConfig.canDeactivate = [];
        }

        const canDeactivateGuards = routeConfig.canDeactivate;

        // ensure that the "PendingChangesGuard" is in effect.
        if (!canDeactivateGuards.find((o) => o == PendingChangesGuard)) {
            canDeactivateGuards.push(PendingChangesGuard);
        }
    }
}
