import { Injectable } from '@angular/core';
import { AuthService } from '@ridango/auth';
import { map, Observable, of } from 'rxjs';
import { IdentityService, IdentityServiceUser } from './identity-service';

@Injectable({
    providedIn: 'root',
})
export class AuthIdentityService implements IdentityService {
    constructor(private authService: AuthService) {}

    get isAuthenticated$() {
        return of(this.authService.isAuthenticated);
    }

    get user$(): Observable<IdentityServiceUser> {
        return this.authService.session$.pipe(
            map((session) => ({
                email: session?.resource?.person.email,
                name: session?.resource?.activeRole.organization.fullName,
                role: session?.resource?.activeRole.role.name,
                picture: null,
                initials: session.resource?.activeRole.organization.fullName?.charAt(0),
            }))
        );
    }

    logout(): void {
        this.authService.logout();
    }

    login(): void {
        this.authService.authenticate();
    }
}
