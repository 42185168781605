import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';

import { MatPaginatorIntl } from '@angular/material/paginator';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateFnsAdapter, MatDateFnsModule } from '@angular/material-date-fns-adapter';

import { MESSAGE_FORMAT_CONFIG } from 'ngx-translate-messageformat-compiler';

import { I18nService } from './i18n.service';
import { PaginatorI18n } from './paginator-i18n';

import localeEt from '@angular/common/locales/et';
import localeEnGB from '@angular/common/locales/en-GB';
import localeRu from '@angular/common/locales/ru';
import localeLt from '@angular/common/locales/lt';
import localeFi from '@angular/common/locales/fi';
import { et, enGB, ru, lt, fi, sv } from 'date-fns/locale';

registerLocaleData(localeEt);
registerLocaleData(localeEnGB);
registerLocaleData(localeRu);
registerLocaleData(localeLt);
registerLocaleData(localeFi);

@NgModule({
    imports: [CommonModule, MatDateFnsModule],
    providers: [
        I18nService,
        {
            provide: LOCALE_ID,
            deps: [I18nService],
            useFactory: (i18nService: I18nService) => {
                return i18nService.activeLang;
            },
        },
        {
            provide: DateAdapter,
            useClass: DateFnsAdapter,
        },
        {
            provide: MAT_DATE_LOCALE,
            deps: [I18nService],
            useFactory: (i18nService: I18nService) => {
                switch (i18nService.activeLang) {
                    case 'et': {
                        return et;
                    }
                    case 'ru': {
                        return ru;
                    }
                    case 'lt': {
                        return lt;
                    }
                    case 'fi': {
                        return fi;
                    }
                    case 'sv': {
                        return sv;
                    }
                    case 'en-GB': {
                        return enGB;
                    }
                    default: {
                        return enGB;
                    }
                }
            },
        },
        { provide: MatPaginatorIntl, useClass: PaginatorI18n },
        { provide: MESSAGE_FORMAT_CONFIG, useValue: { locales: ['en-GB', 'et', 'ru', 'lt', 'fi', 'sv'] } },
    ],
})
export class I18nModule {}
