import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExceptionComponent } from './exception/exception.component';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [ExceptionComponent],
    exports: [ExceptionComponent],
    imports: [CommonModule, RouterModule, MatIconModule],
})
export class ExceptionsModule {}
