import { Inject, Injectable, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'tpsMoney',
})
@Injectable({
    providedIn: 'root'
})
export class TpsMoneyPipe implements PipeTransform {
    private readonly NON_BREAKING_SPACE = '\xa0';

    constructor(@Inject(LOCALE_ID) private localeId: string) {}

    transform(value: number, currencyInput: string): string {
        const currency = currencyInput ? ' ' + currencyInput : '';
        return (
            Number(value / 100)
                .toLocaleString(this.localeId)
                .split(this.NON_BREAKING_SPACE)
                .join(' ') + currency
        );
    }
}
