import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { ClearFiltersComponent } from './clear-filters/clear-filters.component';
import { TableFiltersComponent } from './table-filters/table-filters.component';

@NgModule({
    declarations: [TableFiltersComponent, ClearFiltersComponent],
    exports: [TableFiltersComponent, ClearFiltersComponent],
    imports: [CommonModule, TranslateModule, MatIconModule, MatButtonModule, ReactiveFormsModule],
})
export class TableFiltersModule {}
