import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'payments-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ModalComponent {
    @Input() title: string;
    @Input() loading = false;
}
