<payments-modal [title]="title | translate">
    <ng-container content>
        <p>{{ question | translate }}</p>
    </ng-container>

    <ng-container actions>
        <button mat-button (click)="onNoClick()" [color]="noButtonColor" [disabled]="loading">
            {{ noButtonText | translate }}
        </button>
        <button mat-raised-button (click)="onYesClick()" cdkFocusInitial [color]="yesButtonColor" [loading]="loading">
            {{ yesButtonText | translate }}
        </button>
    </ng-container>
</payments-modal>
