import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { catchError, EMPTY, Observable, throwError } from 'rxjs';
import { SnackbarService } from '../widgets/snackbar/snackbar.service';

@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {
    private readonly IGNORE_URLS = ['assets/i18n'];

    constructor(
        private router: Router,
        private notificationService: SnackbarService,
        private translateService: TranslateService
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (this.IGNORE_URLS.some((ignored) => request.url.includes(ignored))) {
            return next.handle(request);
        }
        return next.handle(request).pipe(catchError((response) => this.handleError(response)));
    }

    private handleError(response) {
        if (!(response instanceof HttpErrorResponse)) {
            return throwError(() => response);
        }

        if (response.status === 401) {
            return throwError(() => response);
        }

        if (response.status === 403) {
            this.router.navigate(['']);
            return EMPTY;
        }

        this.callNotification(response);

        return throwError(() => response);
    }

    private callNotification(response: HttpErrorResponse) {
        if (!response.error?.systemCodeDescription) {
            this.notificationService.addError('notifications.unexpectedError');
            return;
        }

        const translationKey = 'adminResponseCodes.' + response.error.systemCodeDescription;
        const translated = response.error.additionalInfo
            ? this.translateService.instant(translationKey, response.error.additionalInfo)
            : this.translateService.instant(translationKey);

        if (translationKey === translated) {
            this.notificationService.addError('notifications.unexpectedError');
            return;
        }

        this.notificationService.addError(translated);
    }
}
