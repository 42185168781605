import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { RuiFieldComponent, RuiFieldLabelComponent, RuiFieldValueComponent } from './field.component';

@NgModule({
    imports: [CommonModule, NgxSkeletonLoaderModule],
    declarations: [RuiFieldComponent, RuiFieldLabelComponent, RuiFieldValueComponent],
    exports: [RuiFieldComponent, RuiFieldLabelComponent, RuiFieldValueComponent],
})
export class RuiFieldModule {}
