import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { map, Observable } from 'rxjs';

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
    constructor(private confirmationService: FuseConfirmationService) {}

    canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
        if (typeof component.canDeactivate !== 'function') {
            return true;
        }

        // if there are no pending changes, just allow deactivation; else confirm first
        return component.canDeactivate() ? true : this.confirm();
    }

    private confirm(): Observable<boolean> {
        return this.confirmationService
            .open({
                title: 'Navigate away?',
                message: 'Changes that you made may not be saved.',
                dismissible: true,
                icon: {
                    show: false,
                },
                actions: {
                    confirm: {
                        color: 'primary',
                    },
                },
            })
            .afterClosed()
            .pipe(map((result) => result === 'confirmed'));
    }
}
