import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@ridango/auth';
import { from } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { AuditTrailService } from '../services/audit-trail/audit-trail.service';

@Component({
    selector: 'payments-after-login',
    template: ``,
    styles: [''],
})
export class AfterLoginComponent {
    constructor(
        private router: Router,
        private authService: AuthService,
        private auditTrailService: AuditTrailService
    ) {
        from(this.router.navigate(['']))
            .pipe(
                mergeMap(() => this.authService.session$),
                take(1),
                mergeMap(() => this.auditTrailService.login())
            )
            .subscribe();
    }
}
