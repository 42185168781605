<ng-container *ngIf="identityService.isAuthenticated$ | async; else loggedOut">
    <ng-container *ngIf="identityService.user$ | async as user">
        <button class="-mr-4" mat-button [matMenuTriggerFor]="userActions">
            <div class="flex flex-row items-center">
                <div class="flex flex-col text-right p-2">
                    <span class="leading-4">{{ user.name }}</span>
                    <span *ngIf="user.role" class="text-sm leading-4 text-gray-400">{{ user.role }}</span>
                </div>
                <div>
                    <img class="w-7 h-7 rounded-full" *ngIf="user.picture" [src]="user.picture" />
                    <div class="w-7 h-7 rounded-full bg-gray-200 flex items-center justify-center"
                         *ngIf="!user.picture">{{ user.initials | uppercase }}</div>
                </div>
            </div>
        </button>
        <mat-menu [xPosition]="'before'" #userActions="matMenu">
            <ng-content></ng-content>
        </mat-menu>
    </ng-container>
</ng-container>

<ng-template #loggedOut>
    <button mat-button (click)="identityService.login()">Log in</button>
</ng-template>
