import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuditTrailResponseEntry, AuditTrailQueryParams } from './audit-trail.models';
import { Page } from '../common.models';

@Injectable({
    providedIn: 'root',
})
export class AuditTrailService {
    constructor(private http: HttpClient) {}

    login() {
        return this.http.post('/admin/audittrail/login', null);;
    }

    logout() {
        return this.http.post('/admin/audittrail/logout', null);;
    }

    getAll(params: Partial<AuditTrailQueryParams>) {
        const url = `/admin/audittrail`;
        return this.http.get<Page<AuditTrailResponseEntry>>(url, { params });
    }
}
