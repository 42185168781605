import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, OnDestroy, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConfig as OAuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, filter, Observable, Subject, Subscription } from 'rxjs';
import { AuthSession } from './auth.model';

export const AUTH_CONFIG = new InjectionToken<string>('portal-auth-config');

export type AuthConfig = OAuthConfig & {
    sessionUrl: string;
};

@Injectable()
export class AuthService implements OnDestroy {
    private subscriptions: Subscription[] = [];
    private _session$ = new BehaviorSubject<AuthSession>(null);
    private _isAuthenticated$ = new BehaviorSubject<boolean>(false);
    private _isAuthenticated = false;

    get session() {
        return this._session$.getValue();
    }

    get isAuthenticated() {
        return this._isAuthenticated;
    }

    private _beforeLogOut$ = new Subject();
    beforeLogOut$ = this._beforeLogOut$.asObservable();

    constructor(
        @Optional() @Inject(AUTH_CONFIG) private authConfig: AuthConfig,
        private oauth: OAuthService,
        private http: HttpClient,
        private router: Router
    ) {
        this.oauth.configure(authConfig);

        this.oauth.events.pipe(filter((e) => e.type === 'logout'))
            .subscribe(() => {
                this.logoutComplete();
            });

        this._isAuthenticated$.pipe(filter(authenticated => authenticated))
            .subscribe(() => {
                this.processRedirect();
            });

        // handle case where user has been logged out in some other window while this window was hidden
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible' && !this.oauth.hasValidAccessToken()) {
                this.logoutComplete();
            }
        });

        // handle case where user has been logged out in some other window while this window was active
        window.addEventListener('storage', (event) => {
            if (event.key === 'access_token' && document.visibilityState === 'visible' && !this.oauth.hasValidAccessToken()) {
                this.logoutComplete();
            }
        });
    }

    get session$(): Observable<AuthSession> {
        return this._session$.pipe(filter((data) => data !== null));
    }

    check(): Observable<boolean> {
        return new Observable<boolean>((observable) => {
            this.oauth
                .tryLoginCodeFlow()
                .then(() => {
                    if (this.oauth.hasValidAccessToken()) {
                        this.subscriptions.push(
                            this.currentSessionRequest().subscribe({
                                next: (session: AuthSession) => {
                                    this._isAuthenticated = true;
                                    this._session$.next(session);

                                    observable.next(this._isAuthenticated);
                                    observable.complete();

                                    this._isAuthenticated$.next(this._isAuthenticated);
                                },
                                error: () => {
                                    this.logout();
                                },
                            })
                        );
                    } else {
                        observable.next(false);
                        observable.complete();
                    }
                })
                .catch(() => {
                    this.logout();
                });
        });
    }

    processRedirect() {
        if (!this.oauth.state) {
            return;
        }

        const redirectURL = decodeURIComponent(this.oauth.state);
        this.router.navigateByUrl(redirectURL);
    }

    currentSessionRequest() {
        return this.http.get(this.authConfig.sessionUrl);
    }

    authenticate(redirectURL?: string) {
        this.logout();
        this.oauth.initLoginFlow(redirectURL);
    }

    logout() {
        this._beforeLogOut$.next(null);
        this.oauth.logOut(true);
    }

    ngOnDestroy() {
        this.subscriptions?.forEach((s) => s.unsubscribe());
    }

    private logoutComplete() {
        if (!this._isAuthenticated) {
            return;
        }

        this._isAuthenticated = false;
        this._isAuthenticated$.next(this._isAuthenticated);

        this.router.navigate(['login'], {queryParams: {redirectURL: this.router.url}})
    }
}
