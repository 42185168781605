import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule as DirectivesModulePayments, LoadingModule, TableFiltersModule } from '@payments';
import { RuiButtonModule } from '@ridango/ui/button';
import { RuiCardModule } from '@ridango/ui/card';
import { RuiFieldModule } from '@ridango/ui/field';
import { RuiPageModule } from '@ridango/ui/page';
import { RuiTableModule } from '@ridango/ui/table';
import { AuthorizationModule } from '../core/authorization/authorization.module';
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';
import { ActionsModule } from '../widgets/actions/actions.module';
import { BreadcrumbsModule } from '../widgets/breadcrumbs/breadcrumbs.module';
import { DescriptionListModule } from '../widgets/description-list/description-list.module';
import { FormUtilsModule } from '../widgets/form-utils/form-utils.module';
import { LayoutModule } from '../widgets/layout/layout.module';
import { SnackbarModule } from '../widgets/snackbar/snackbar.module';

@NgModule({
    exports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,

        AuthorizationModule,
        DirectivesModulePayments,
        DirectivesModule,
        PipesModule,
        LayoutModule,
        TableFiltersModule,
        ActionsModule,
        FormUtilsModule,
        LoadingModule,

        SnackbarModule,
        BreadcrumbsModule,
        DescriptionListModule,

        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatSelectModule,
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        MatSlideToggleModule,

        RuiCardModule,
        RuiPageModule,
        RuiFieldModule,
        RuiButtonModule,
        RuiTableModule,
    ],
})
export class SharedModule {}
