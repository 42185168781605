import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/paginator';
import {
    RuiHeaderCell,
    RuiHeaderCellFilter,
    RuiHeaderCellLabel,
    RuiTableLoaderComponent,
    RuiTableLoaderColComponent,
} from './table.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
    imports: [CommonModule, NgxSkeletonLoaderModule],
    declarations: [
        RuiHeaderCell,
        RuiHeaderCellLabel,
        RuiHeaderCellFilter,
        RuiTableLoaderComponent,
        RuiTableLoaderColComponent,
    ],
    exports: [RuiHeaderCell, RuiHeaderCellLabel, RuiHeaderCellFilter, RuiTableLoaderComponent],
    providers: [
        {
            provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
            useValue: { pageSize: 10, pageSizeOptions: [5, 10, 50, 100] },
        },
    ],
})
export class RuiTableModule {}
