import { Component, Input } from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[payments-no-results]',
    templateUrl: './no-results.component.html',
    styleUrls: ['./no-results.component.scss'],
})
export class NoResultsComponent {
    @Input() isFilterValid = true;
    @Input() filterInvalidInfo = 'common.noResultsFilterInvalid';
}
