<payments-modal [title]="'common.selectRole' | translate">
    <ng-container content>
        <mat-form-field appearance="fill" class="w-full">
            <mat-label>Role</mat-label>
            <input matInput type="text" [matAutocomplete]="auto" [formControl]="rolesControl" />
            <button mat-icon-button matSuffix (click)="clear()" [attr.aria-label]="'Clear input'">
                <mat-icon>clear</mat-icon>
            </button>

            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayRole">
                <mat-optgroup *ngFor="let group of filteredRolesGroups$ | async" [label]="group.companyName">
                    <mat-option *ngFor="let role of group.roles" [value]="role">
                        <div class="check" *ngIf="role === activeRole">
                            <mat-icon>check</mat-icon>
                        </div>
                        {{ role.roleName }}
                    </mat-option>
                </mat-optgroup>
            </mat-autocomplete>
        </mat-form-field>
    </ng-container>

    <ng-container actions>
        <payments-form-discard-with-confirmation [form]="rolesControl" (confirm)="close()">
        </payments-form-discard-with-confirmation>

        <button mat-raised-button (click)="submit()" cdkFocusInitial color="primary" class="action" [loading]="loading">
            {{ 'common.useRole' | translate }}
        </button>
    </ng-container>
</payments-modal>
