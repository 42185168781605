import { NgModule } from '@angular/core';
import { CustomDatePipe } from './date/custom-date.pipe';
import { MinifyTokenPipe } from './minify-token/minify-token.pipe';
import { PemPipe } from './pem.pipe';
import { SplitIntoBlocksPipe } from './split-into-blocks/split-into-blocks.pipe';
import { TruncatePipe } from './truncate/truncate.pipe';

@NgModule({
    declarations: [TruncatePipe, MinifyTokenPipe, CustomDatePipe, SplitIntoBlocksPipe, PemPipe],
    exports: [TruncatePipe, MinifyTokenPipe, CustomDatePipe, SplitIntoBlocksPipe, PemPipe],
    imports: [],
})
export class PipesModule {}
