import { MatPaginatorIntl } from '@angular/material/paginator';

export class CustomPaginatorIntl extends MatPaginatorIntl {
    constructor() {
        super();
    }

    getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === Number.POSITIVE_INFINITY) {
            const lowerBound = page * pageSize + 1;
            const upperBound = (page + 1) * pageSize;
            return `${lowerBound} – ${upperBound}`;
        }

        // Default implementation

        if (length == 0 || pageSize == 0) {
            return `0 of ${length}`;
        }

        length = Math.max(length, 0);

        const startIndex = page * pageSize;

        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

        return `${startIndex + 1} – ${endIndex} of ${length}`;
    };
}
