import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ModalService } from '../../modal/modal.service';

@Component({
    selector: 'payments-form-discard-with-confirmation',
    templateUrl: './form-discard-with-confirmation.component.html',
    styleUrls: ['./form-discard-with-confirmation.component.scss'],
})
export class FormDiscardWithConfirmationComponent {
    @Input() form: AbstractControl;
    @Input() question = 'common.confirmCancelQuestion';
    @Input() disabled = false;
    @Output() confirm = new EventEmitter<void>();

    constructor(private modalService: ModalService) {}

    handleClick() {
        if (this.form.dirty) {
            return this.openConfirmationPrompt();
        }

        this.confirm.emit();
    }

    @HostListener('document:keydown.escape', ['$event'])
    handleEscapeKey() {
        if (this.form.dirty) {
            return this.openConfirmationPrompt();
        } else {
            this.modalService.close();
        }
    }

    private openConfirmationPrompt() {
        const options = {
            question: this.question,
            actionCallback: () => this.confirm.emit(),
        };
        this.modalService.openConfirm(options);
    }
}
