import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'payments-title-container',
    templateUrl: './title-container.component.html',
    styleUrls: ['./title-container.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TitleContainerComponent {
    @Input() title: string;
    @Input() variant: 'primary' | 'secondary' = 'primary';
}
