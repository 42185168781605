import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { SnackbarOutletComponent } from './snackbar-outlet/snackbar-outlet.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { NotificationBarComponent } from './notification-bar/notification-bar.component';
import { MatIconModule } from '@angular/material/icon';
import { NotificationsModule } from '@ridango/ui/notifications';

const components = [SnackbarComponent, SnackbarOutletComponent, NotificationBarComponent];
@NgModule({
    declarations: [...components],
    exports: [...components],
    imports: [CommonModule, TranslateModule, MatButtonModule, MatIconModule, NotificationsModule],
})
export class SnackbarModule {}
