import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseLayoutComponent } from './layout.component';
import { EmptyLayoutModule } from './layouts/empty/empty.module';
import { ModernLayoutModule } from './layouts/horizontal/modern/modern.module';
import { ClassyLayoutModule } from './layouts/vertical/classy/classy.module';
import { DenseLayoutModule } from './layouts/vertical/dense/dense.module';
import { CommonModule } from '@angular/common';

const layoutModules = [
    // Empty
    EmptyLayoutModule,

    // Horizontal navigation
    ModernLayoutModule,

    // Vertical navigation
    ClassyLayoutModule,
    DenseLayoutModule,
];

@NgModule({
    declarations: [
        FuseLayoutComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatTooltipModule,
        ...layoutModules
    ],
    exports: [
        FuseLayoutComponent,
        ...layoutModules
    ]
})
export class FuseLayoutModule {
}
