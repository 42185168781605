import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DescriptionListColumnComponent } from './description-list-column/description-list-column.component';
import { DescriptionListItemComponent } from './description-list-item/description-list-item.component';
import { DescriptionListComponent } from './description-list/description-list.component';

const components = [
    DescriptionListComponent,
    DescriptionListItemComponent,
    DescriptionListColumnComponent,
]

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [
    CommonModule,
    TranslateModule
  ]
})
export class DescriptionListModule { }
