import { Inject, Injectable, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { format as formatDate, Locale } from 'date-fns';
import * as locales from 'date-fns/locale';

@Pipe({
    name: 'date',
})
@Injectable({
    providedIn: 'root'
})
export class CustomDatePipe implements PipeTransform {
    private locale: Locale;

    constructor(@Inject(LOCALE_ID) private localeId: string) {
        this.locale = locales[this.localeId.replace('-', '')] ?? locales.enGB;
    }

    transform(date: string | Date, format = 'P pp', defaultUTC = true) {
        if (date == null) {
            return null;
        }

        let dateString = date.toString();

        const hasTime = !!dateString.match(/T/);
        const hasTimezone = !!dateString.match(/:.+[+\-z]/i);
        if (hasTime && !hasTimezone && defaultUTC) {
            dateString += 'Z';
        }

        return formatDate(new Date(dateString), format, { locale: this.locale });
    }
}
