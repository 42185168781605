import { NgModule } from '@angular/core';
import { RuiPageModule } from '@ridango/ui/page';
import { BreadcrumbsDirective } from './breadcrumbs.directive';

@NgModule({
    declarations: [BreadcrumbsDirective],
    exports: [BreadcrumbsDirective],
    imports: [RuiPageModule],
})
export class BreadcrumbsModule {}
