import { Component, Input, ViewEncapsulation } from '@angular/core';

export interface DescriptionListItem {
    label: string;
    value: string | number | boolean | null;
}

@Component({
    selector: 'payments-description-list',
    templateUrl: './description-list.component.html',
    styleUrls: ['./description-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DescriptionListComponent {
    @Input() items?: DescriptionListItem[];
    @Input() heading: string;
}
