import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Route, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthorizationService } from './authorization.service';

@Injectable({
    providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
    constructor(private router: Router, private authorizationService: AuthorizationService) {}

    canActivate(
        route: ActivatedRouteSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.currentUserHasRoutePermission(route);
    }

    private currentUserHasRoutePermission(route: Route | ActivatedRouteSnapshot): Observable<boolean> {
        if (route?.data?.roles) {
            const hasPermission = this.authorizationService.hasSomeRole(route.data.roles);
            if (!hasPermission) {
                this.router.navigate(['/']);
            }
            return of(hasPermission);
        }
        return of(true);
    }
}
