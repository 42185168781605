<!-- Button -->
<ng-container *ngIf="i18nService && availableLangs.length > 1">
    <button mat-flat-button [matMenuTriggerFor]="languages">
        <mat-icon class="icon-size-5" svgIcon="heroicons_outline:globe"></mat-icon>
        <span class="ml-2">{{ getActiveLabel() }}</span>
    </button>

    <!-- Language menu -->
    <mat-menu [xPosition]="'before'" #languages="matMenu">
        <ng-container *ngFor="let lang of availableLangs; trackBy: trackByFn">
            <button mat-menu-item (click)="setActiveLang(lang.id)">
                <span class="flex items-center">
                    <span class="ml-3">{{ lang.label }}</span>
                </span>
            </button>
        </ng-container>
    </mat-menu>
</ng-container>
