import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { StorageFactory } from '@payments/list-manager';
import { AuthService } from '@ridango/auth';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
    constructor(private _authService: AuthService, private _router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._check(redirectUrl);
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._check(redirectUrl);
    }

    private _check(redirectURL: string): Observable<boolean> {
        // Check the authentication status
        return this._authService.check().pipe(
            switchMap((authenticated) => {
                if (!authenticated) {
                    // Redirect to the sign-in page
                    this._router.navigate(['login'], { queryParams: { redirectURL } });

                    this.clearStoreAndSaveRedirectUrl(redirectURL);

                    // Prevent the access
                    return of(false);
                }

                this.navigateRedirectUrlIfExists();

                // Allow the access
                return of(true);
            })
        );
    }

    private navigateRedirectUrlIfExists() {
        const redirectUrl = StorageFactory.sessionStorage.getItem<string>('redirectURL');
        if (redirectUrl) {
            StorageFactory.sessionStorage.removeItem('redirectURL');
            this._router.navigateByUrl(redirectUrl);
        }
    }

    private clearStoreAndSaveRedirectUrl(redirectURL) {
        StorageFactory.sessionStorage.clear();
        StorageFactory.urlStorage.clear();
        StorageFactory.sessionStorage.setItem('redirectURL', redirectURL);
    }
}
