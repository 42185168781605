<div class="flex flex-row">
    <div class="flex-grow space-y-4">
        <div class="flex flex-row" *ngFor="let item of data.items; let isLast = last">
            <div class="flex mr-2">
                <mat-icon
                    *ngIf="data.type === 'error'"
                    class="text-current"
                    svgIcon="heroicons_outline:exclamation-circle"></mat-icon>
                <mat-icon
                    *ngIf="data.type === 'success'"
                    class="text-current"
                    svgIcon="heroicons_outline:check-circle"></mat-icon>
                <mat-icon
                    *ngIf="data.type === 'warning' || data.type === 'info'"
                    class="text-current"
                    svgIcon="heroicons_outline:information-circle"></mat-icon>
            </div>
            <div class="flex flex-col flex-grow justify-start pr-8">
                <span class="text-current font-bold" style="line-height: 24px;">
                    {{ item.title }}<span *ngIf="item.code">: {{ item.code }}</span>
                </span>
                <div *ngIf="item.detail" class="text-current">{{ item.detail }}</div>
                <div *ngIf="item.value" class="text-current">{{ item.value }}</div>
                <div *ngIf="item.id" class="text-current text-sm italic color">({{ item.id }})</div>
                <div *ngIf="item.action">
                    <button (click)="item.action.callbackFn()" mat-flat-button color="accent">{{ item.action.label }}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="absolute top-4 right-4">
        <button class="-mt-2 -mr-2" mat-icon-button (click)="dismiss()">
            <mat-icon class="text-current" svgIcon="heroicons_outline:x"></mat-icon>
        </button>
    </div>
</div>
