import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FuseAlertType } from '@fuse/components/alert';
import { FuseLoadingService } from '@fuse/services/loading';
import { Subscription } from 'rxjs';
import { ModalService } from '../../modal/modal.service';
import { FormGenerator } from '../form-generator.models';
import { FormModal } from './form-modal.interface';

@Component({
    selector: 'payments-form-generator-in-modal',
    templateUrl: './form-generator-in-modal.component.html',
    styleUrls: ['./form-generator-in-modal.component.scss'],
})
export class FormGeneratorInModalComponent implements FormModal, OnInit, OnDestroy {
    @Input() title: string;
    @Input() alertMessage: string;
    @Input() alertLevel: FuseAlertType = 'info';
    @Input() formGenerator: FormGenerator;
    @Input() submitButtonText = 'common.save';

    @Output() formSubmit = new EventEmitter();

    loading = false;
    private subscription: Subscription;

    constructor(private modalService: ModalService, private loadingService: FuseLoadingService) {}

    ngOnInit(): void {
        this.subscription = this.loadingService.show$.subscribe((value) => {
            if (!value) {
                this.loading = false;
            }
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    submit(form: UntypedFormGroup) {
        form.updateValueAndValidity();
        form.markAllAsTouched();
        if (!form.valid) return;

        this.loading = true;
        this.formSubmit.emit(form.value);
    }

    cancel() {
        this.modalService.closeAll();
    }
}
